import React, { useEffect, useState } from "react";
import Layout from "../components/organisms/layout/layout";
import ProjectsTemplate from "../components/templates/projects-template/projectsTemplate";
import { makeSearch } from "../utils/search";

const SearchResults = () => {
  const [items, setItems] = useState([]);
  const [toSearch, setToSearch] = useState("");

  useEffect(() => {
    getSearchParam();
  }, []);

  const getSearchParam = () => {
    const searchParam = new URL(window.location.href).searchParams.get("toSearch");
    setToSearch(searchParam);
    setItems(makeSearch(searchParam));
  }

  return (
    <Layout title="Dicar | Resultados búsqueda">
      <ProjectsTemplate
        data={
          {
            textTitle: `${items.length ? "Resultados de búsqueda": "No hay resultados"} para "${toSearch}"`,
            applications: items
          }
        }
      />
    </Layout>
  )
}

export default SearchResults;