import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby';

import Anchor from "../../atoms/anchor/anchor";

import "./projectsTemplate.scss";

const itemsPerPage = 12;
const maxPaginationItems = 5;
const projectsType = [
  { title: "Proyectos Internacionales Enerpac", company: "Enerpac" },
  { title: "Proyectos Nacionales Dicar", company: "Dicar" },
];

const ProjectsTemplate = (props) => {
  const data = useStaticQuery(graphql`
		query QUERY_PROJECTS {
			allContentfulPaginaProyectos {
        nodes {
          applications {
            challenge {
              raw
            }
            images {
              file {
                fileName
                url
              }
            }
            name
            projectType
            slug
            solution {
              raw
            }
          }
        }
      }
		}
  `);

  const {
    applications = [],
  } = props.data || data?.allContentfulPaginaProyectos?.nodes[0];

  const [appsType, setAppsType] = useState("Enerpac");
  const [itemsToShow, setItemsToShow] = useState([]);
  const [paginatorItems, setPaginatorItems] = useState([]);
  const [paginatorItemsToShow, setPaginatorItemsToShow] = useState([]);
  const [page, setPage] = useState(1);

  const initialApps = props.data
    ? applications
    : applications.filter((app) => app.projectType === appsType);

  useEffect(() => {
    setItemsToShow(initialApps.slice(0, itemsPerPage));
    setPaginatorItems(getPaginatorItems());
    setPaginatorItemsToShow(getPaginatorItems().slice(0, maxPaginationItems));
  }, [props, appsType]);

  useEffect(() => {
    const sliceInit = itemsPerPage * (page - 1);
    setItemsToShow(initialApps.slice(sliceInit, sliceInit + itemsPerPage));
    handlePaginatorItemsToShow();
  }, [page, props]);

  const getPaginatorItems = () => (
    [...Array(Math.ceil(initialApps.length / itemsPerPage))]
      .map((_, i) => i + 1)
  )

  const handlePaginatorItemsToShow = () => {
    const half = maxPaginationItems / 2;
    if (paginatorItems.length) {
      const startSlice = [...paginatorItems].pop() - page >= half 
        ? Math.max(0, page - half)
        : -maxPaginationItems;
      const endSlice = [...paginatorItems].pop() - page >= half 
        ? Math.max(maxPaginationItems, page + half)
        : undefined;
      setPaginatorItemsToShow(paginatorItems.slice(startSlice, endSlice))
    }
  }

  const stepPaginator = (direction) => (
    setPage((page) => direction === "prev" ? page - 1 : page + 1)
  )

  const goToPaginator = (goTo) => setPage(goTo);

  const handleAppsType = (type) => setAppsType(type);

  return (
    <div className="t-projects">
      {!props.data &&
        <div className="t-projects__titles">
          {projectsType.map((pt) => (
            initialApps.length > 0 &&
            <h2
              key={pt.title}
              className={`t-projects__titles__title ${
                appsType === pt.company ? "" : "t-projects__titles__title--not-selected"}`
              }
              onClick={() => handleAppsType(pt.company)}
            >
              {pt.title}
            </h2>
          ))}
        </div>
      }
      <div className="t-projects__content">
        {itemsToShow.map((item) => (
          <div
            key={item.slug}
            className="t-projects__content__project"
          >
            <Anchor
              className={`t-projects__content__project__image ${
                props.data ? "t-projects__content__project__image--product" : ""}`
              }
              href={item.slug}
            >
              <img
                src={item.images[0].file.url}
                alt={item.images[0].file.fileName}
              />
            </Anchor>
            <div className="t-projects__content__project__text">
              <h2 className="t-projects__content__project__text__title">
                <Anchor href={item.slug}>
                  {item.name}
                </Anchor>
              </h2>
              <p className="t-projects__content__project__text__desc">
                {item.solution 
                  ? JSON.parse(item.solution.raw).content[0].content[0].value
                  : item.challenge 
                    ? JSON.parse(item.challenge.raw).content[0].content[0].value
                    : ""
                }
              </p>
            </div>
            <Anchor
              className={`icon-chevron-right t-projects__content__project__arrow ${
                props.data ? "t-projects__content__project__arrow--product" : ""}`
              }
              href={item.slug}
            />
          </div>
        ))}
      </div>
      {initialApps.length > itemsPerPage &&
        <div className="t-projects__pagination">
          <span
            className={`icon-chevron-left t-projects__pagination__arrow ${
              page === 1 ? "t-projects__pagination__arrow--disabled" : ""}`
            }
            onClick={() => page !== 1 ? stepPaginator("prev") : false}
          />
          {paginatorItemsToShow.map((item) => (
            <p
              key={item}
              className={`t-projects__pagination__item ${
                item === page ? "t-projects__pagination__item--active" : ""}`
              }
              onClick={() => item !== page ? goToPaginator(item) : false}
            >
              {item}
            </p>
          ))}
          <span
            className={`icon-chevron-right t-projects__pagination__arrow ${
              page === [...paginatorItems].pop() ? "t-projects__pagination__arrow--disabled" : ""}`
            }
            onClick={() => page !== [...paginatorItems].pop() ? stepPaginator("next") : false}
          />
        </div>
      }
    </div>
  )
}

export default ProjectsTemplate;