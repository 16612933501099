import * as data from "../../static/metas/meta.json";

const deleteTildes = (value) => {
  value = value.replace(/á/gi,"a");
  value = value.replace(/é/gi,"e");
  value = value.replace(/í/gi,"i");
  value = value.replace(/ó/gi,"o");
  value = value.replace(/ú/gi,"u");
  value = value.replace(/ñ/gi,"n");
  return value;
}

export const makeSearch = (toSearch) => {
  console.log(data, toSearch)
  return data.filter((item) => deleteTildes(item.indexSearch).toLowerCase().includes(toSearch))
}